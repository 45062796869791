<template>
  <div>
    <v-simple-table
      v-if="hasWorkdays"
      :fixed-header="setFixedHeader"
      :height="setHeight"
      class="jornada-list"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th />
            <th class="text-center">
              {{ $t("components.lists.mirrors.drive") }}
            </th>
            <th class="text-center">
              {{ $t("components.lists.mirrors.available") }}
            </th>
            <th class="text-center">
              {{ $t("components.lists.mirrors.wait") }}
            </th>
            <th class="text-center">
              {{ $t("components.lists.mirrors.half_extra_hour") }}
            </th>
            <th class="text-center">
              {{ $t("components.lists.mirrors.full_extra_hour") }}
            </th>
            <th class="text-center">
              {{ $t("components.lists.mirrors.night_increase") }}
            </th>
            <th class="text-center">
              {{ $t("components.lists.mirrors.meal") }}
            </th>
            <th class="text-center">
              {{ $t("components.lists.mirrors.rest") }}
            </th>
            <th class="text-center">
              {{ $t("components.lists.mirrors.overnight") }}
            </th>
            <!-- <th class="text-center">
              {{ $t("components.lists.mirrors.pay_drive") }}
            </th> -->
            <th class="text-center">
              {{ $t("components.lists.mirrors.pay_wait") }}
            </th>
            <th class="text-center">
              {{ $t("components.lists.mirrors.reason") }}
            </th>
            <th class="text-center">
              {{ $t("components.lists.mirrors.day_off_type") }}
            </th>
            <th v-if="showButtons" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="workday in workdays"
            :key="workday.id"
            class="list-row"
            @click="showWorkday(workday.id)"
          >
            <td class="day">
              <div :class="pendingUpdateClass(workday)">
                <strong class="date">
                  <span>{{ dayOfWeek(workday.created_at, "iii") }}</span>
                  <span>{{ dayOfWeek(workday.created_at, "dd/MM") }}</span>
                </strong>
              </div>
            </td>
            <td
              class="text-center mirror"
              :class="{ line: workday.day_off }"
            >
              {{ formatHour(workday.drive_time, false) }}
            </td>
            <td
              class="text-center mirror"
              :class="{ line: workday.day_off }"
            >
              {{ formatHour(workday.available_time, false) }}
            </td>
            <td
              class="text-center mirror"
              :class="{ line: workday.day_off }"
            >
              {{ formatHour(workday.wait_time, false) }}
            </td>
            <td
              class="text-center mirror"
              :class="{ line: workday.day_off }"
            >
              {{ formatHour(workday.half_extra_hour, false) }}
            </td>
            <td
              class="text-center mirror"
              :class="{ line: workday.day_off }"
            >
              {{ formatHour(workday.full_extra_hour, false) }}
            </td>
            <td
              class="text-center mirror"
              :class="{ line: workday.day_off }"
            >
              {{ formatHour(workday.night_increase, false) }}
            </td>
            <td
              class="text-center mirror"
              :class="{ line: workday.day_off }"
            >
              {{ formatHour(workday.meal_time, false) }}
            </td>
            <td
              class="text-center mirror"
              :class="{ line: workday.day_off }"
            >
              {{ formatHour(workday.rest_time, false) }}
            </td>
            <td class="text-center mirror">
              {{ workdayIsOff(workday) }}
            </td>
            <!-- <td
              class="text-center mirror"
              :class="{ line: workday.day_off }"
            >
              {{ formatHour(workday.pay_drive_time, false) }}
            </td> -->
            <td
              class="text-center mirror"
              :class="{ line: workday.day_off }"
            >
              {{ formatHour(workday.pay_wait_time, false) }}
            </td>
            <td
              v-if="workday.vacation"
              class="text-center mirror font-sm"
            >
              {{ $t("components.lists.mirrors.vacation") }}
            </td>
            <td
              v-else-if="workday.holiday"
              class="text-center mirror font-sm"
            >
              {{ $t("components.lists.mirrors.holiday") }}
            </td>
            <td
              v-else
              class="text-center mirror font-sm"
            >
              {{ workday.adjustment_reason }}
            </td>
            <td class="text-center mirror font-sm">
              {{ getDayOffType(workday, false, formatHour(workday.dsr_suppressed_time, false)) }}
            </td>
            <td
              v-if="showButtons"
              class="text-right buttons pl-8 py-4"
            >
              <div>
                <v-icon
                  class="icon pa-2"
                  :color="pendingUpdate(workday)"
                >
                  mdi-message-alert
                </v-icon>
                <v-icon
                  class="icon"
                  :color="pendingUpdate(workday)"
                >
                  mdi-chevron-right
                </v-icon>
              </div>
            </td>
          </tr>
          <tr
            v-if="totalizer"
            class="list-row"
          >
            <td class="text-center">
              {{ $t("components.lists.mirrors.totalizer") }}
            </td>
            <td class="text-center time">
              {{ formatHour(totalizer.drive_time, false) }}
            </td>
            <td class="text-center time">
              {{ formatHour(totalizer.available_time, false) }}
            </td>
            <td class="text-center time">
              {{ formatHour(totalizer.wait_time, false) }}
            </td>
            <td class="text-center time">
              {{ formatHour(totalizer.half_extra_hour, false) }}
            </td>
            <td class="text-center time">
              {{ formatHour(totalizer.full_extra_hour, false) }}
            </td>
            <td class="text-center time">
              {{ formatHour(totalizer.night_increase, false) }}
            </td>
            <td class="text-center time">
              {{ formatHour(totalizer.meal_time, false) }}
            </td>
            <td class="text-center time">
              {{ formatHour(totalizer.rest_time, false) }}
            </td>
            <td class="text-center time">
              {{ formatHour(totalizer.overnight_time, false) }}
            </td>
            <!-- <td class="text-center time">
              {{ formatHour(totalizer.pay_drive_time, false) }}
            </td> -->
            <td class="text-center time">
              {{ formatHour(totalizer.pay_wait_time, false) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <table
      v-if="print && hasWorkdays"
      class="table-missing"
    >
      <td class="missing-total">
        <div class="d-flex">
          <p class="text-missing">
            {{ $t("components.lists.mirrors.overnight_missing_total") }}
          </p>
          <p class="ma-0 ml-2">
            {{ formatHour(overnightMissingTotal) }}
          </p>
        </div>

        <div class="d-flex">
          <p class="text-missing">
            {{ $t("components.lists.mirrors.meal_missing_total") }}
          </p>
          <p class="ma-0 ml-2">
            {{ formatHour(mealMissingTotal) }}
          </p>
        </div>

        <div class="d-flex">
          <p class="text-missing">
            {{ $t("components.lists.mirrors.dsr_suppressed_total") }}
          </p>
          <p class="ma-0 ml-2">
            {{ formatHour(drsSuppressedTotal) }}
          </p>
        </div>

        <div class="d-flex">
          <p class="text-missing">
            {{ $t("components.lists.mirrors.quantity_dsr_suppressed") }}
          </p>
          <p class="ma-0 ml-2">
            {{ quantityDrsSuppressed }}
          </p>
        </div>
      </td>
    </table>

    <placeholder
      v-if="!hasWorkdays"
      :message="$t('components.lists.mirrors.empty')"
    />
  </div>
</template>

<script>
import Placeholder from '@/components/lists/Placeholder.vue';
import formats from '@/mixins/formats';
import getDayOffType from '@/mixins/getDayOffType';

export default {
  name: 'MirrorShowList',
  components: {
    Placeholder,
  },
  mixins: [formats, getDayOffType],
  props: {
    showButtons: {
      type: Boolean,
      default: false,
    },
    workdays: {
      type: Array,
      default: () => [],
    },
    totalizer: {
      type: Object,
      default: () => {},
    },
    print: {
      type: Boolean,
      default: false,
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    overnightMissingTotal: {
      type: Number,
      default: 0,
    },
    mealMissingTotal: {
      type: Number,
      default: 0,
    },
    drsSuppressedTotal: {
      type: Number,
      default: 0,
    },
    quantityDrsSuppressed: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    hasWorkdays() {
      if (this.workdays.length !== 0) {
        return true;
      }
      return false;
    },
    setFixedHeader() {
      return this.fixedHeader;
    },
    setHeight() {
      return this.fixedHeader ? '560px' : '';
    },
  },
  methods: {
    pendingUpdate(workday) {
      if (workday.has_update_pending) {
        return this.$vuetify.theme.themes.light.accent;
      }

      return this.$vuetify.theme.themes.light.list_item;
    },
    pendingUpdateClass(workday) {
      if (workday.has_update_pending) {
        return 'content-day has-pending-update';
      }

      return 'content-day';
    },
    showWorkday(workdayId) {
      this.$emit('showWorkdayDetails', workdayId);
    },
    workdayIsOff(workday) {
      if (workday && (workday.day_off || workday.cct_respite || workday.dsr_respite)) {
        return ' - ';
      }

      return this.formatHour(workday.overnight_time, false);
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-data-table.jornada-list {
  background-color: $color_background;
  font-family: "Acumin-Pro-Bold";
  color: $color_list_body;

  .v-data-table__wrapper > table > thead > tr > th {
    font-family: "Acumin-Pro-Regular";
    background-color: $color_background;
    color: $color_list_header;
    font-size: 12px;
    font-weight: 400;
  }

  .mirror {
    font-family: "Acumin-Pro-Bold";
    width: 12% !important;

    &.font-sm {
      font-size: 8px;
    }

    &.line {
      text-decoration: line-through;
    }
  }

  .list-row {
    padding: 24px 16px;
    height: 64px;
    cursor: pointer;

    .day {
      text-transform: capitalize;
      font-size: 14px;

      .date {
        display: flex;
        flex-direction: column;
      }

      .content-day {
        text-align: center;
        width: 40px;

        &.has-pending-update {
          color: $color_primary;
        }
      }
    }
  }
}

@media print {
  .theme--light.v-data-table.jornada-list {
    * {
      font-size: 10px;
    }

    .v-data-table__wrapper > table > thead > tr > th {
      padding: 0;
      height: auto;
      font-size: 10px;
    }

    .list-row {
      padding: 6px;
      height: auto;

      .day {
        .date {
          flex-direction: row;
          gap: 2px;
        }
      }

     .mirror {
        padding: 0 10px;
      }

      &:not(:last-child) > td:last-child {
        white-space: nowrap;
      }
    }
  }
}

.table-missing {
  width: 100%;

  .missing-total {
    width: 100%;
    padding: 10px 20px;
    border-top: thin solid rgba(0, 0, 0, 0.12);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text-missing {
      margin: 0;
    }
  }
}
</style>
